import revive_payload_client_4sVQNw7RlN from "/var/www/fadin-main/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/var/www/fadin-main/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/var/www/fadin-main/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/var/www/fadin-main/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/var/www/fadin-main/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/var/www/fadin-main/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/var/www/fadin-main/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/var/www/fadin-main/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/var/www/fadin-main/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/var/www/fadin-main/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_I4dbrL2rYz from "/var/www/fadin-main/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import filepond_client_aTErpFZFqB from "/var/www/fadin-main/plugins/filepond.client.ts";
import stripe_6FZUdDHpIZ from "/var/www/fadin-main/plugins/stripe.ts";
import swiper_eteSCvuYkd from "/var/www/fadin-main/plugins/swiper.ts";
import tippy_xFBYqfAPCu from "/var/www/fadin-main/plugins/tippy.ts";
import datePicker_y6PJpeIUqN from "/var/www/fadin-main/plugins/datePicker.ts";
import mapbox_client_HMmMV6F7A6 from "/var/www/fadin-main/plugins/mapbox.client.ts";
import baseDataApi_K1ZrvcMWeE from "/var/www/fadin-main/plugins/baseDataApi.ts";
import businessesDataApi_fcJN9MNXKO from "/var/www/fadin-main/plugins/businessesDataApi.ts";
import classifiedDataApi_Rg2azlEBx8 from "/var/www/fadin-main/plugins/classifiedDataApi.ts";
import directives_8CcCirWtnE from "/var/www/fadin-main/plugins/directives.ts";
import fundraisingDataApi_95GB2NiXya from "/var/www/fadin-main/plugins/fundraisingDataApi.ts";
import islamicCenterDataApi_FaNPJR8pvv from "/var/www/fadin-main/plugins/islamicCenterDataApi.ts";
import locationDetector_jlmoJsBS8D from "/var/www/fadin-main/plugins/locationDetector.ts";
import mainDataApi_R69B22Odzg from "/var/www/fadin-main/plugins/mainDataApi.ts";
import maska_UHaKf2z1iQ from "/var/www/fadin-main/plugins/maska.ts";
import multiselect_MptySqkW4e from "/var/www/fadin-main/plugins/multiselect.ts";
import newsDataApi_egUqiOg0fi from "/var/www/fadin-main/plugins/newsDataApi.ts";
import scrollPositionDetector_7WsRQ202JV from "/var/www/fadin-main/plugins/scrollPositionDetector.ts";
import toggleButton_dYCjJvYnFy from "/var/www/fadin-main/plugins/toggleButton.ts";
import vee_validate_KcKlKmvCrJ from "/var/www/fadin-main/plugins/vee-validate.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_I4dbrL2rYz,
  filepond_client_aTErpFZFqB,
  stripe_6FZUdDHpIZ,
  swiper_eteSCvuYkd,
  tippy_xFBYqfAPCu,
  datePicker_y6PJpeIUqN,
  mapbox_client_HMmMV6F7A6,
  baseDataApi_K1ZrvcMWeE,
  businessesDataApi_fcJN9MNXKO,
  classifiedDataApi_Rg2azlEBx8,
  directives_8CcCirWtnE,
  fundraisingDataApi_95GB2NiXya,
  islamicCenterDataApi_FaNPJR8pvv,
  locationDetector_jlmoJsBS8D,
  mainDataApi_R69B22Odzg,
  maska_UHaKf2z1iQ,
  multiselect_MptySqkW4e,
  newsDataApi_egUqiOg0fi,
  scrollPositionDetector_7WsRQ202JV,
  toggleButton_dYCjJvYnFy,
  vee_validate_KcKlKmvCrJ
]