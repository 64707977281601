import { default as _91id_93Kt0MK1GIMzMeta } from "/var/www/fadin-main/pages/businesses/[slug]/avail-coupon/[id].vue?macro=true";
import { default as indexndAS0S1MkpMeta } from "/var/www/fadin-main/pages/businesses/[slug]/index.vue?macro=true";
import { default as indexyuM64m8dssMeta } from "/var/www/fadin-main/pages/businesses/index.vue?macro=true";
import { default as searchx2w0F5gjR4Meta } from "/var/www/fadin-main/pages/businesses/search.vue?macro=true";
import { default as indexoRaejGAC7XMeta } from "/var/www/fadin-main/pages/classified/[slug]/index.vue?macro=true";
import { default as indexbzzfBOfaHZMeta } from "/var/www/fadin-main/pages/classified/index.vue?macro=true";
import { default as searchu2cQIWRAIAMeta } from "/var/www/fadin-main/pages/classified/search.vue?macro=true";
import { default as amenitiesDPVfOzUQKkMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/amenities.vue?macro=true";
import { default as checkout0zerXH00THMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/checkout.vue?macro=true";
import { default as downgradenuGWLKZtXzMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/downgrade.vue?macro=true";
import { default as indexLlPOn4CIpnMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/index.vue?macro=true";
import { default as plans5C2wmLwKDfMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/plans.vue?macro=true";
import { default as update_45cardbxWeRfqLZWMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/update-card.vue?macro=true";
import { default as edit0v0W3nMj58Meta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/[id]/edit.vue?macro=true";
import { default as indexbcM9GlCoHlMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/[id]/index.vue?macro=true";
import { default as create_45newU8XsK9GaMXMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/create-new.vue?macro=true";
import { default as indexwD12BdBaOoMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/index.vue?macro=true";
import { default as redeemVaNBIwJMSwMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/redeem.vue?macro=true";
import { default as custom_45productsYY1PKFPp4RMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/custom-products.vue?macro=true";
import { default as custom_45serviceswSM03WILUmMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/custom-services.vue?macro=true";
import { default as editYFnHIXUQxWMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/edit.vue?macro=true";
import { default as galleryilkxWNDxgrMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/gallery.vue?macro=true";
import { default as general_45productsxjHchtuvQDMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/general-products.vue?macro=true";
import { default as general_45serviceshyDga5kTO5Meta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/general-services.vue?macro=true";
import { default as indexzhY4SqRLAmMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/index.vue?macro=true";
import { default as postsNXwktyvUx8Meta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/posts.vue?macro=true";
import { default as reviewsOgJ5XLuuQnMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/reviews.vue?macro=true";
import { default as sales1VmpK3WedMMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/sales.vue?macro=true";
import { default as timing20qhwzJUevMeta } from "/var/www/fadin-main/pages/dashboard/businesses/[slug]/timing.vue?macro=true";
import { default as availed_45couponsATVeZeYT8yMeta } from "/var/www/fadin-main/pages/dashboard/businesses/availed-coupons.vue?macro=true";
import { default as create_45newwpa2CcKvBHMeta } from "/var/www/fadin-main/pages/dashboard/businesses/create-new.vue?macro=true";
import { default as indexLs6Yo9iiaQMeta } from "/var/www/fadin-main/pages/dashboard/businesses/index.vue?macro=true";
import { default as liked_45businessesqL3Oi8PScZMeta } from "/var/www/fadin-main/pages/dashboard/businesses/liked-businesses.vue?macro=true";
import { default as reviewsOoIyijz0xnMeta } from "/var/www/fadin-main/pages/dashboard/businesses/reviews.vue?macro=true";
import { default as edithtbklpYt1zMeta } from "/var/www/fadin-main/pages/dashboard/classified/[slug]/edit.vue?macro=true";
import { default as galleryF0JKzi4uFuMeta } from "/var/www/fadin-main/pages/dashboard/classified/[slug]/gallery.vue?macro=true";
import { default as indexEKy6mEUPLaMeta } from "/var/www/fadin-main/pages/dashboard/classified/[slug]/index.vue?macro=true";
import { default as orders5zSXD0TePsMeta } from "/var/www/fadin-main/pages/dashboard/classified/[slug]/orders.vue?macro=true";
import { default as create_45newP8kpI6A8rgMeta } from "/var/www/fadin-main/pages/dashboard/classified/create-new.vue?macro=true";
import { default as indexmj51fNQJUXMeta } from "/var/www/fadin-main/pages/dashboard/classified/index.vue?macro=true";
import { default as saved_45adsKjEMpWesoKMeta } from "/var/www/fadin-main/pages/dashboard/classified/saved-ads.vue?macro=true";
import { default as donationspy4qHq6cemMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/[slug]/donations.vue?macro=true";
import { default as editKj6bbLsu9jMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/[slug]/edit.vue?macro=true";
import { default as galleryywZogRg8PuMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/[slug]/gallery.vue?macro=true";
import { default as index3ZwNvpi9VKMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/[slug]/index.vue?macro=true";
import { default as timeline_45updatesINkt7LFTc1Meta } from "/var/www/fadin-main/pages/dashboard/fundraising/[slug]/timeline-updates.vue?macro=true";
import { default as create_45newYjqCt080sFMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/create-new.vue?macro=true";
import { default as indexNdiLlHvI5zMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/index.vue?macro=true";
import { default as my_45donationsjRcr8fMVQjMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/my-donations.vue?macro=true";
import { default as saved_45campaignsrqlYCbOPhsMeta } from "/var/www/fadin-main/pages/dashboard/fundraising/saved-campaigns.vue?macro=true";
import { default as index6fLJdkdfqbMeta } from "/var/www/fadin-main/pages/dashboard/index.vue?macro=true";
import { default as editOykrdJ6YrnMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/edit.vue?macro=true";
import { default as eid_45al_45adha_45prayer_45timingGgD30x2LYLMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/eid-al-adha-prayer-timing.vue?macro=true";
import { default as eid_45al_45fitr_45prayer_45timingFrDVXybzmuMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/eid-al-fitr-prayer-timing.vue?macro=true";
import { default as gallery0RpgbypdoSMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/gallery.vue?macro=true";
import { default as indexG9xa1bcbBhMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/index.vue?macro=true";
import { default as jummah_45prayer_45timingowWXwjds8IMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/jummah-prayer-timing.vue?macro=true";
import { default as prayer_45timingqb0JlIQdOjMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/prayer-timing.vue?macro=true";
import { default as create_45newioKeOCoFkFMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/create-new.vue?macro=true";
import { default as indexQDDMQa0NSOMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/index.vue?macro=true";
import { default as subscribed_45islamic_45centersShf78FhWovMeta } from "/var/www/fadin-main/pages/dashboard/islamic-centers/subscribed-islamic-centers.vue?macro=true";
import { default as editRZXL00GGisMeta } from "/var/www/fadin-main/pages/dashboard/news/[slug]/edit.vue?macro=true";
import { default as galleryr01xs8lPjWMeta } from "/var/www/fadin-main/pages/dashboard/news/[slug]/gallery.vue?macro=true";
import { default as indexT3zUVEKaJeMeta } from "/var/www/fadin-main/pages/dashboard/news/[slug]/index.vue?macro=true";
import { default as ordersyQMYYRuLqiMeta } from "/var/www/fadin-main/pages/dashboard/news/[slug]/orders.vue?macro=true";
import { default as create_45newyzwCg2ETvxMeta } from "/var/www/fadin-main/pages/dashboard/news/create-new.vue?macro=true";
import { default as indexLjKvNFHDNoMeta } from "/var/www/fadin-main/pages/dashboard/news/index.vue?macro=true";
import { default as pinned_45news6nUOCtainJMeta } from "/var/www/fadin-main/pages/dashboard/news/pinned-news.vue?macro=true";
import { default as profileIo4SfuycZDMeta } from "/var/www/fadin-main/pages/dashboard/profile.vue?macro=true";
import { default as indexBukV4mUwG8Meta } from "/var/www/fadin-main/pages/forgot-password/index.vue?macro=true";
import { default as resetzqArtnZlzPMeta } from "/var/www/fadin-main/pages/forgot-password/reset.vue?macro=true";
import { default as indexUr9t8STDEdMeta } from "/var/www/fadin-main/pages/fundraising/[slug]/donate/index.vue?macro=true";
import { default as thank_45you1YntSeUfwKMeta } from "/var/www/fadin-main/pages/fundraising/[slug]/donate/thank-you.vue?macro=true";
import { default as indexnB8WN3oj66Meta } from "/var/www/fadin-main/pages/fundraising/[slug]/index.vue?macro=true";
import { default as indexQhfHFNEr7KMeta } from "/var/www/fadin-main/pages/fundraising/index.vue?macro=true";
import { default as search4Tyxwj6n80Meta } from "/var/www/fadin-main/pages/fundraising/search.vue?macro=true";
import { default as supporttiKphuBwNZMeta } from "/var/www/fadin-main/pages/help/support.vue?macro=true";
import { default as indexMoai86MlsOMeta } from "/var/www/fadin-main/pages/index.vue?macro=true";
import { default as indexbNm2c0TirfMeta } from "/var/www/fadin-main/pages/islamic-centers/[slug]/index.vue?macro=true";
import { default as indexASge68WxIdMeta } from "/var/www/fadin-main/pages/islamic-centers/index.vue?macro=true";
import { default as searchf4NFugrDgUMeta } from "/var/www/fadin-main/pages/islamic-centers/search.vue?macro=true";
import { default as terms_45of_45usewEfmw7YAHGMeta } from "/var/www/fadin-main/pages/legal/terms-of-use.vue?macro=true";
import { default as login2VAEh0r508Meta } from "/var/www/fadin-main/pages/login.vue?macro=true";
import { default as indexQBTeFukq78Meta } from "/var/www/fadin-main/pages/news/announcements/[slug]/index.vue?macro=true";
import { default as indexk8fLwBBXOcMeta } from "/var/www/fadin-main/pages/news/announcements/index.vue?macro=true";
import { default as search9o2mApe4C3Meta } from "/var/www/fadin-main/pages/news/announcements/search.vue?macro=true";
import { default as indexNnLmYsQjPTMeta } from "/var/www/fadin-main/pages/news/index.vue?macro=true";
import { default as indexsPhpg8wb2SMeta } from "/var/www/fadin-main/pages/news/stories/[slug]/index.vue?macro=true";
import { default as indexpRaTN9DOAIMeta } from "/var/www/fadin-main/pages/news/stories/index.vue?macro=true";
import { default as cookie_45policyvjCpEGjy0kMeta } from "/var/www/fadin-main/pages/policies/cookie-policy.vue?macro=true";
import { default as privacy_45policyQafPowTr5bMeta } from "/var/www/fadin-main/pages/policies/privacy-policy.vue?macro=true";
import { default as register447JP8WASXMeta } from "/var/www/fadin-main/pages/register.vue?macro=true";
import { default as indexxiwWqFGDr8Meta } from "/var/www/fadin-main/pages/users/[id]/index.vue?macro=true";
import { default as verify_45emailNpA0hyayi6Meta } from "/var/www/fadin-main/pages/verify-email.vue?macro=true";
import { default as verify_45your_45emailzDPxe0xHj5Meta } from "/var/www/fadin-main/pages/verify-your-email.vue?macro=true";
export default [
  {
    name: "businesses-slug-avail-coupon-id",
    path: "/businesses/:slug()/avail-coupon/:id()",
    meta: _91id_93Kt0MK1GIMzMeta || {},
    component: () => import("/var/www/fadin-main/pages/businesses/[slug]/avail-coupon/[id].vue")
  },
  {
    name: "businesses-slug",
    path: "/businesses/:slug()",
    meta: indexndAS0S1MkpMeta || {},
    component: () => import("/var/www/fadin-main/pages/businesses/[slug]/index.vue")
  },
  {
    name: "businesses",
    path: "/businesses",
    meta: indexyuM64m8dssMeta || {},
    component: () => import("/var/www/fadin-main/pages/businesses/index.vue")
  },
  {
    name: "businesses-search",
    path: "/businesses/search",
    meta: searchx2w0F5gjR4Meta || {},
    component: () => import("/var/www/fadin-main/pages/businesses/search.vue")
  },
  {
    name: "classified-slug",
    path: "/classified/:slug()",
    meta: indexoRaejGAC7XMeta || {},
    component: () => import("/var/www/fadin-main/pages/classified/[slug]/index.vue")
  },
  {
    name: "classified",
    path: "/classified",
    meta: indexbzzfBOfaHZMeta || {},
    component: () => import("/var/www/fadin-main/pages/classified/index.vue")
  },
  {
    name: "classified-search",
    path: "/classified/search",
    meta: searchu2cQIWRAIAMeta || {},
    component: () => import("/var/www/fadin-main/pages/classified/search.vue")
  },
  {
    name: "dashboard-businesses-slug-amenities",
    path: "/dashboard/businesses/:slug()/amenities",
    meta: amenitiesDPVfOzUQKkMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/amenities.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-checkout",
    path: "/dashboard/businesses/:slug()/billing/checkout",
    meta: checkout0zerXH00THMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/checkout.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-downgrade",
    path: "/dashboard/businesses/:slug()/billing/downgrade",
    meta: downgradenuGWLKZtXzMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/downgrade.vue")
  },
  {
    name: "dashboard-businesses-slug-billing",
    path: "/dashboard/businesses/:slug()/billing",
    meta: indexLlPOn4CIpnMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/index.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-plans",
    path: "/dashboard/businesses/:slug()/billing/plans",
    meta: plans5C2wmLwKDfMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/plans.vue")
  },
  {
    name: "dashboard-businesses-slug-billing-update-card",
    path: "/dashboard/businesses/:slug()/billing/update-card",
    meta: update_45cardbxWeRfqLZWMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/billing/update-card.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-id-edit",
    path: "/dashboard/businesses/:slug()/coupons/:id()/edit",
    meta: edit0v0W3nMj58Meta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/[id]/edit.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-id",
    path: "/dashboard/businesses/:slug()/coupons/:id()",
    meta: indexbcM9GlCoHlMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/[id]/index.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-create-new",
    path: "/dashboard/businesses/:slug()/coupons/create-new",
    meta: create_45newU8XsK9GaMXMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/create-new.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons",
    path: "/dashboard/businesses/:slug()/coupons",
    meta: indexwD12BdBaOoMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/index.vue")
  },
  {
    name: "dashboard-businesses-slug-coupons-redeem",
    path: "/dashboard/businesses/:slug()/coupons/redeem",
    meta: redeemVaNBIwJMSwMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/coupons/redeem.vue")
  },
  {
    name: "dashboard-businesses-slug-custom-products",
    path: "/dashboard/businesses/:slug()/custom-products",
    meta: custom_45productsYY1PKFPp4RMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/custom-products.vue")
  },
  {
    name: "dashboard-businesses-slug-custom-services",
    path: "/dashboard/businesses/:slug()/custom-services",
    meta: custom_45serviceswSM03WILUmMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/custom-services.vue")
  },
  {
    name: "dashboard-businesses-slug-edit",
    path: "/dashboard/businesses/:slug()/edit",
    meta: editYFnHIXUQxWMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/edit.vue")
  },
  {
    name: "dashboard-businesses-slug-gallery",
    path: "/dashboard/businesses/:slug()/gallery",
    meta: galleryilkxWNDxgrMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/gallery.vue")
  },
  {
    name: "dashboard-businesses-slug-general-products",
    path: "/dashboard/businesses/:slug()/general-products",
    meta: general_45productsxjHchtuvQDMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/general-products.vue")
  },
  {
    name: "dashboard-businesses-slug-general-services",
    path: "/dashboard/businesses/:slug()/general-services",
    meta: general_45serviceshyDga5kTO5Meta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/general-services.vue")
  },
  {
    name: "dashboard-businesses-slug",
    path: "/dashboard/businesses/:slug()",
    meta: indexzhY4SqRLAmMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/index.vue")
  },
  {
    name: "dashboard-businesses-slug-posts",
    path: "/dashboard/businesses/:slug()/posts",
    meta: postsNXwktyvUx8Meta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/posts.vue")
  },
  {
    name: "dashboard-businesses-slug-reviews",
    path: "/dashboard/businesses/:slug()/reviews",
    meta: reviewsOgJ5XLuuQnMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/reviews.vue")
  },
  {
    name: "dashboard-businesses-slug-sales",
    path: "/dashboard/businesses/:slug()/sales",
    meta: sales1VmpK3WedMMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/sales.vue")
  },
  {
    name: "dashboard-businesses-slug-timing",
    path: "/dashboard/businesses/:slug()/timing",
    meta: timing20qhwzJUevMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/[slug]/timing.vue")
  },
  {
    name: "dashboard-businesses-availed-coupons",
    path: "/dashboard/businesses/availed-coupons",
    meta: availed_45couponsATVeZeYT8yMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/availed-coupons.vue")
  },
  {
    name: "dashboard-businesses-create-new",
    path: "/dashboard/businesses/create-new",
    meta: create_45newwpa2CcKvBHMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/create-new.vue")
  },
  {
    name: "dashboard-businesses",
    path: "/dashboard/businesses",
    meta: indexLs6Yo9iiaQMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/index.vue")
  },
  {
    name: "dashboard-businesses-liked-businesses",
    path: "/dashboard/businesses/liked-businesses",
    meta: liked_45businessesqL3Oi8PScZMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/liked-businesses.vue")
  },
  {
    name: "dashboard-businesses-reviews",
    path: "/dashboard/businesses/reviews",
    meta: reviewsOoIyijz0xnMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/businesses/reviews.vue")
  },
  {
    name: "dashboard-classified-slug-edit",
    path: "/dashboard/classified/:slug()/edit",
    meta: edithtbklpYt1zMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/[slug]/edit.vue")
  },
  {
    name: "dashboard-classified-slug-gallery",
    path: "/dashboard/classified/:slug()/gallery",
    meta: galleryF0JKzi4uFuMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/[slug]/gallery.vue")
  },
  {
    name: "dashboard-classified-slug",
    path: "/dashboard/classified/:slug()",
    meta: indexEKy6mEUPLaMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/[slug]/index.vue")
  },
  {
    name: "dashboard-classified-slug-orders",
    path: "/dashboard/classified/:slug()/orders",
    meta: orders5zSXD0TePsMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/[slug]/orders.vue")
  },
  {
    name: "dashboard-classified-create-new",
    path: "/dashboard/classified/create-new",
    meta: create_45newP8kpI6A8rgMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/create-new.vue")
  },
  {
    name: "dashboard-classified",
    path: "/dashboard/classified",
    meta: indexmj51fNQJUXMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/index.vue")
  },
  {
    name: "dashboard-classified-saved-ads",
    path: "/dashboard/classified/saved-ads",
    meta: saved_45adsKjEMpWesoKMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/classified/saved-ads.vue")
  },
  {
    name: "dashboard-fundraising-slug-donations",
    path: "/dashboard/fundraising/:slug()/donations",
    meta: donationspy4qHq6cemMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/[slug]/donations.vue")
  },
  {
    name: "dashboard-fundraising-slug-edit",
    path: "/dashboard/fundraising/:slug()/edit",
    meta: editKj6bbLsu9jMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/[slug]/edit.vue")
  },
  {
    name: "dashboard-fundraising-slug-gallery",
    path: "/dashboard/fundraising/:slug()/gallery",
    meta: galleryywZogRg8PuMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/[slug]/gallery.vue")
  },
  {
    name: "dashboard-fundraising-slug",
    path: "/dashboard/fundraising/:slug()",
    meta: index3ZwNvpi9VKMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/[slug]/index.vue")
  },
  {
    name: "dashboard-fundraising-slug-timeline-updates",
    path: "/dashboard/fundraising/:slug()/timeline-updates",
    meta: timeline_45updatesINkt7LFTc1Meta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/[slug]/timeline-updates.vue")
  },
  {
    name: "dashboard-fundraising-create-new",
    path: "/dashboard/fundraising/create-new",
    meta: create_45newYjqCt080sFMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/create-new.vue")
  },
  {
    name: "dashboard-fundraising",
    path: "/dashboard/fundraising",
    meta: indexNdiLlHvI5zMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/index.vue")
  },
  {
    name: "dashboard-fundraising-my-donations",
    path: "/dashboard/fundraising/my-donations",
    meta: my_45donationsjRcr8fMVQjMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/my-donations.vue")
  },
  {
    name: "dashboard-fundraising-saved-campaigns",
    path: "/dashboard/fundraising/saved-campaigns",
    meta: saved_45campaignsrqlYCbOPhsMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/fundraising/saved-campaigns.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: index6fLJdkdfqbMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/index.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-edit",
    path: "/dashboard/islamic-centers/:slug()/edit",
    meta: editOykrdJ6YrnMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/edit.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-eid-al-adha-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/eid-al-adha-prayer-timing",
    meta: eid_45al_45adha_45prayer_45timingGgD30x2LYLMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/eid-al-adha-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-eid-al-fitr-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/eid-al-fitr-prayer-timing",
    meta: eid_45al_45fitr_45prayer_45timingFrDVXybzmuMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/eid-al-fitr-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-gallery",
    path: "/dashboard/islamic-centers/:slug()/gallery",
    meta: gallery0RpgbypdoSMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/gallery.vue")
  },
  {
    name: "dashboard-islamic-centers-slug",
    path: "/dashboard/islamic-centers/:slug()",
    meta: indexG9xa1bcbBhMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/index.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-jummah-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/jummah-prayer-timing",
    meta: jummah_45prayer_45timingowWXwjds8IMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/jummah-prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-slug-prayer-timing",
    path: "/dashboard/islamic-centers/:slug()/prayer-timing",
    meta: prayer_45timingqb0JlIQdOjMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/[slug]/prayer-timing.vue")
  },
  {
    name: "dashboard-islamic-centers-create-new",
    path: "/dashboard/islamic-centers/create-new",
    meta: create_45newioKeOCoFkFMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/create-new.vue")
  },
  {
    name: "dashboard-islamic-centers",
    path: "/dashboard/islamic-centers",
    meta: indexQDDMQa0NSOMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/index.vue")
  },
  {
    name: "dashboard-islamic-centers-subscribed-islamic-centers",
    path: "/dashboard/islamic-centers/subscribed-islamic-centers",
    meta: subscribed_45islamic_45centersShf78FhWovMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/islamic-centers/subscribed-islamic-centers.vue")
  },
  {
    name: "dashboard-news-slug-edit",
    path: "/dashboard/news/:slug()/edit",
    meta: editRZXL00GGisMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/[slug]/edit.vue")
  },
  {
    name: "dashboard-news-slug-gallery",
    path: "/dashboard/news/:slug()/gallery",
    meta: galleryr01xs8lPjWMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/[slug]/gallery.vue")
  },
  {
    name: "dashboard-news-slug",
    path: "/dashboard/news/:slug()",
    meta: indexT3zUVEKaJeMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/[slug]/index.vue")
  },
  {
    name: "dashboard-news-slug-orders",
    path: "/dashboard/news/:slug()/orders",
    meta: ordersyQMYYRuLqiMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/[slug]/orders.vue")
  },
  {
    name: "dashboard-news-create-new",
    path: "/dashboard/news/create-new",
    meta: create_45newyzwCg2ETvxMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/create-new.vue")
  },
  {
    name: "dashboard-news",
    path: "/dashboard/news",
    meta: indexLjKvNFHDNoMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/index.vue")
  },
  {
    name: "dashboard-news-pinned-news",
    path: "/dashboard/news/pinned-news",
    meta: pinned_45news6nUOCtainJMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/news/pinned-news.vue")
  },
  {
    name: "dashboard-profile",
    path: "/dashboard/profile",
    meta: profileIo4SfuycZDMeta || {},
    component: () => import("/var/www/fadin-main/pages/dashboard/profile.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: indexBukV4mUwG8Meta || {},
    component: () => import("/var/www/fadin-main/pages/forgot-password/index.vue")
  },
  {
    name: "forgot-password-reset",
    path: "/forgot-password/reset",
    meta: resetzqArtnZlzPMeta || {},
    component: () => import("/var/www/fadin-main/pages/forgot-password/reset.vue")
  },
  {
    name: "fundraising-slug-donate",
    path: "/fundraising/:slug()/donate",
    meta: indexUr9t8STDEdMeta || {},
    component: () => import("/var/www/fadin-main/pages/fundraising/[slug]/donate/index.vue")
  },
  {
    name: "fundraising-slug-donate-thank-you",
    path: "/fundraising/:slug()/donate/thank-you",
    meta: thank_45you1YntSeUfwKMeta || {},
    component: () => import("/var/www/fadin-main/pages/fundraising/[slug]/donate/thank-you.vue")
  },
  {
    name: "fundraising-slug",
    path: "/fundraising/:slug()",
    meta: indexnB8WN3oj66Meta || {},
    component: () => import("/var/www/fadin-main/pages/fundraising/[slug]/index.vue")
  },
  {
    name: "fundraising",
    path: "/fundraising",
    meta: indexQhfHFNEr7KMeta || {},
    component: () => import("/var/www/fadin-main/pages/fundraising/index.vue")
  },
  {
    name: "fundraising-search",
    path: "/fundraising/search",
    meta: search4Tyxwj6n80Meta || {},
    component: () => import("/var/www/fadin-main/pages/fundraising/search.vue")
  },
  {
    name: "help-support",
    path: "/help/support",
    meta: supporttiKphuBwNZMeta || {},
    component: () => import("/var/www/fadin-main/pages/help/support.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexMoai86MlsOMeta || {},
    component: () => import("/var/www/fadin-main/pages/index.vue")
  },
  {
    name: "islamic-centers-slug",
    path: "/islamic-centers/:slug()",
    meta: indexbNm2c0TirfMeta || {},
    component: () => import("/var/www/fadin-main/pages/islamic-centers/[slug]/index.vue")
  },
  {
    name: "islamic-centers",
    path: "/islamic-centers",
    meta: indexASge68WxIdMeta || {},
    component: () => import("/var/www/fadin-main/pages/islamic-centers/index.vue")
  },
  {
    name: "islamic-centers-search",
    path: "/islamic-centers/search",
    meta: searchf4NFugrDgUMeta || {},
    component: () => import("/var/www/fadin-main/pages/islamic-centers/search.vue")
  },
  {
    name: "legal-terms-of-use",
    path: "/legal/terms-of-use",
    meta: terms_45of_45usewEfmw7YAHGMeta || {},
    component: () => import("/var/www/fadin-main/pages/legal/terms-of-use.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login2VAEh0r508Meta || {},
    component: () => import("/var/www/fadin-main/pages/login.vue")
  },
  {
    name: "news-announcements-slug",
    path: "/news/announcements/:slug()",
    meta: indexQBTeFukq78Meta || {},
    component: () => import("/var/www/fadin-main/pages/news/announcements/[slug]/index.vue")
  },
  {
    name: "news-announcements",
    path: "/news/announcements",
    meta: indexk8fLwBBXOcMeta || {},
    component: () => import("/var/www/fadin-main/pages/news/announcements/index.vue")
  },
  {
    name: "news-announcements-search",
    path: "/news/announcements/search",
    meta: search9o2mApe4C3Meta || {},
    component: () => import("/var/www/fadin-main/pages/news/announcements/search.vue")
  },
  {
    name: "news",
    path: "/news",
    meta: indexNnLmYsQjPTMeta || {},
    component: () => import("/var/www/fadin-main/pages/news/index.vue")
  },
  {
    name: "news-stories-slug",
    path: "/news/stories/:slug()",
    meta: indexsPhpg8wb2SMeta || {},
    component: () => import("/var/www/fadin-main/pages/news/stories/[slug]/index.vue")
  },
  {
    name: "news-stories",
    path: "/news/stories",
    meta: indexpRaTN9DOAIMeta || {},
    component: () => import("/var/www/fadin-main/pages/news/stories/index.vue")
  },
  {
    name: "policies-cookie-policy",
    path: "/policies/cookie-policy",
    meta: cookie_45policyvjCpEGjy0kMeta || {},
    component: () => import("/var/www/fadin-main/pages/policies/cookie-policy.vue")
  },
  {
    name: "policies-privacy-policy",
    path: "/policies/privacy-policy",
    meta: privacy_45policyQafPowTr5bMeta || {},
    component: () => import("/var/www/fadin-main/pages/policies/privacy-policy.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: register447JP8WASXMeta || {},
    component: () => import("/var/www/fadin-main/pages/register.vue")
  },
  {
    name: "users-id",
    path: "/users/:id()",
    meta: indexxiwWqFGDr8Meta || {},
    component: () => import("/var/www/fadin-main/pages/users/[id]/index.vue")
  },
  {
    name: "verify-email",
    path: "/verify-email",
    meta: verify_45emailNpA0hyayi6Meta || {},
    component: () => import("/var/www/fadin-main/pages/verify-email.vue")
  },
  {
    name: "verify-your-email",
    path: "/verify-your-email",
    meta: verify_45your_45emailzDPxe0xHj5Meta || {},
    component: () => import("/var/www/fadin-main/pages/verify-your-email.vue")
  }
]