import validate from "/var/www/fadin-main/node_modules/nuxt/dist/pages/runtime/validate.js";
import manifest_45route_45rule from "/var/www/fadin-main/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "email-not-verified": () => import("/var/www/fadin-main/middleware/email-not-verified.ts"),
  "email-verified": () => import("/var/www/fadin-main/middleware/email-verified.ts"),
  unauthenticated: () => import("/var/www/fadin-main/middleware/unauthenticated.ts"),
  "sidebase-auth": () => import("/var/www/fadin-main/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}