import { defineRule } from 'vee-validate';
import { required, email, min, min_value, max, max_value, confirmed, numeric, alpha_spaces, alpha_num, regex } from '@vee-validate/rules';


export default defineNuxtPlugin(nuxtApp => {

  const messages: Record<string, string> = {
    required: 'This field is required',
    email: 'This field must be a valid email',
    min: 'This field must have no less than {length} characters',
    min_value: 'This field must have no less than {length} characters',
    max: 'This field must not exceed {length} characters',
  };

  defineRule('required', required);
  defineRule('email', email);
  defineRule('min', min);
  defineRule('min_value', min_value);
  defineRule('max', max);
  defineRule('max_value', max_value);
  defineRule('confirmed', confirmed);
  defineRule('numeric', numeric);
  defineRule('alpha_spaces', alpha_spaces);
  defineRule('alpha_num', alpha_num);
  defineRule('regex', regex);
  defineRule('minNumberOfFiles', (value: [], minNumberOfFiles: number) => {
    if (minNumberOfFiles && value && value.length < minNumberOfFiles) {
      return `At least ${minNumberOfFiles} images must be attached`
    }
    return true;
  });
  defineRule('maxNumberOfFiles', (value: [], maxNumberOfFiles: number) => {
    if (maxNumberOfFiles && value && value.length > maxNumberOfFiles) {
      return `Cannot attach more than ${maxNumberOfFiles} images`
    }
    return true;
  })

  defineRule('minSelections', (array: any[], minNumberOfSelections: number) => {
    if (minNumberOfSelections && array.length < minNumberOfSelections) {
      return `At least ${minNumberOfSelections} selections must be made`
    }
    return true;
  })

  defineRule('maxSelections', (array: any[], maxNumberOfSelections: number) => {
    if (maxNumberOfSelections && array.length > maxNumberOfSelections) {
      return `Cannot make more than ${maxNumberOfSelections} selections`
    }
    return true;
  })

  defineRule('before_date', (value: string, paramDate: string) => {
    let referenceDate = new Date(value);
    let inputDate = new Date(paramDate);
    if (inputDate < referenceDate) {
      return `Input date must be a date before ${paramDate}`;
    }
    return true;
  });

  defineRule('after_date', (value: string, paramDate: string) => {
    let referenceDate = new Date(value);
    let inputDate = new Date(paramDate);
    if (inputDate > referenceDate) {
      return `Input date must be a date after ${paramDate}`;
    }
    return true;
  });

  defineRule('before_time', (value: string, paramTime: string) => {
    if (paramTime < value) {
      return `Input time must be a time before ${paramTime}`;
    }
    return true;
  });

  defineRule('after_time', (value: string, paramTime: string) => {
    if (paramTime > value) {
      return `Input time must be a time after ${paramTime}`;
    }
    return true;
  });

});

